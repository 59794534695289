@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}